import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import type { BasicProps } from 'quarks/interpolations/basic';

import TrendsMapCard from 'components/Cards/TrendsMapCard/TrendsMapCard';
import Pagination from 'components/Pagination/Pagination';
import FilterForm from 'components/TrendsMap/FilterForm';

import type { DatoCmsEntitySmugglingPlace } from 'graphqlTypes';


type HeroProps = BasicProps & {
  setCategory: Dispatch<SetStateAction<string>>;
  setOrigin: Dispatch<SetStateAction<string>>;
  setDestination: Dispatch<SetStateAction<string>>;
  setPos: Dispatch<SetStateAction<string>>;
  places: DatoCmsEntitySmugglingPlace[];
};

const TrendsMapListing: FC<HeroProps> = ({ setCategory, setOrigin, setDestination, setPos, places }) => {
  const category: string[] = [...new Set(places.map(place => place.categories?.[0]?.name || ''))]
    .filter(item => item !== '')
    .sort((a, b) => (a > b ? 1 : -1));
  const origin: string[] = [...new Set(places.map(place => place.origin || ''))]
    .filter(item => item !== '')
    .sort((a, b) => (a > b ? 1 : -1));
  const destination: string[] = [...new Set(places.map(place => place.destination || ''))]
    .filter(item => item !== '')
    .sort((a, b) => (a > b ? 1 : -1));
  const pos: string[] = [...new Set(places.map(place => place.pointOfSeizure || ''))]
    .filter(item => item !== '')
    .sort((a, b) => (a > b ? 1 : -1));

  const [activeCat, setActiveCat] = useState('');
  const [activeOrigin, setActiveOrigin] = useState('');
  const [activeDestination, setActiveDestination] = useState('');
  const [activePos, setActivePos] = useState('');

  const [filtered, setFiltered] = useState<any[]>(places);
  const [page, setPage] = useState(1);

  const perCount = 10;

  const goPage = (count: number) => {
    setPage(count);
  };

  useEffect(() => {
    const filteredData = places.filter(
      place =>
        (activeCat === '' || activeCat === place.categories?.[0]?.name) &&
        (activeOrigin === '' || activeOrigin === place.origin) &&
        (activeDestination === '' || activeDestination === place.destination) &&
        (activePos === '' || activePos === place.pointOfSeizure),
    );
    setFiltered(filteredData);
    setPage(1);
  }, [activeCat, activeOrigin, activeDestination, activePos]);

  useEffect(() => {
    setCategory(activeCat);
  }, [activeCat]);

  useEffect(() => {
    setOrigin(activeOrigin);
  }, [activeOrigin]);

  useEffect(() => {
    setDestination(activeDestination);
  }, [activeDestination]);

  useEffect(() => {
    setPos(activePos);
  }, [activePos]);

  return (
    <Flex flexDirection="column" md={{ flexDirection: 'row-reverse', gap: '104px' }}>
      <Container width="100%" md={{ flex: '0 0 312px' }}>
        <FilterForm
          category={category}
          origin={origin}
          destination={destination}
          pos={pos}
          setCategory={setActiveCat}
          setDestination={setActiveDestination}
          setOrigin={setActiveOrigin}
          setPos={setActivePos}
        />
      </Container>
      <Container id="card-container" width="100%" md={{ flex: '1 0 0' }}>
        <Heading as="h3" textStyle="md" marginBottom={40} textColor="gray-900" fontWeight="semiBold">
          All Trends
        </Heading>
        <Flex rowGap="40px" columnGap="32px" flexWrap="wrap">
          {filtered &&
            filtered
              .slice((page - 1) * perCount, page * perCount)
              .map((item: any, index: number) => (
                <TrendsMapCard
                  key={`card-${index + 1}`}
                  place={item}
                  width="100%"
                  sm={{ flex: '0 0 calc(50% - 16px)' }}
                />
              ))}
        </Flex>
        {filtered && filtered.length > perCount && (
          <Pagination
            total={filtered?.length || 0}
            currentPage={page}
            goPage={goPage}
            countPerpage={perCount}
            toId="card-container"
            space={120}
          />
        )}
      </Container>
    </Flex>
  );
};

export default TrendsMapListing;

import { PageProps, graphql } from 'gatsby';
import { DatoCmsEntitySmugglingPlaceConnection, DatoCmsSeoField, DatoCmsTemplatePage,  } from 'graphqlTypes';
import { FC, useState } from 'react';

import Section from 'molecules/Section/Section';

import TrendsMapHero from 'components/TrendsMap/TrendsMapHero';
import TrendsMapListing from 'components/TrendsMap/TrendsMapListing';

import Layout from 'layouts/Layout';
import LayoutSection from 'layouts/LayoutSection';

interface PageDataType {
  pageData: DatoCmsTemplatePage;
  allDatoCmsEntitySmugglingPlace: DatoCmsEntitySmugglingPlaceConnection; 
}

const TrendsMap: FC<PageProps<PageDataType>> = ({
  data: {
    pageData: { seo, sections },
    allDatoCmsEntitySmugglingPlace,
  },
  location,
  pageContext,
}) => {
  const [activeCat, setActiveCat] = useState('');
  const [activeOrigin, setActiveOrigin] = useState('');
  const [activeDestination, setActiveDestination] = useState('');
  const [activePos, setActivePos] = useState('');

  const places = allDatoCmsEntitySmugglingPlace.nodes;

  return (
    <Layout seo={seo as DatoCmsSeoField} slug={location.pathname} domain={(pageContext as any)?.domain}>
      <Section
        id="blog-hero"
        paddingTopDesktop="80"
        paddingTopTablet="80"
        paddingTopMobile="80"
        paddingBottomDesktop="56"
        paddingBottomTablet="40"
        paddingBottomMobile="40"
        isOverSection
        backgroundColor="secondaryGrayBlue-900"
      >
        <TrendsMapHero
          activeCat={activeCat}
          activeOrigin={activeOrigin}
          activeDestination={activeDestination}
          activePos={activePos}
          places={places}
        />
      </Section>
      <Section
        id="trends-map-listing-section"
        paddingTopTablet="48"
        paddingTopMobile="48"
        paddingBottomTablet="48"
        paddingBottomMobile="48"
        isOverSection
        backgroundColor="secondaryGrayBlue-25"
      >
        <TrendsMapListing
          setCategory={setActiveCat}
          setDestination={setActiveDestination}
          setOrigin={setActiveOrigin}
          setPos={setActivePos}
          places={places}
        />
      </Section>

      {sections &&
        sections.map(
          (section, index) =>
            section && (
              <LayoutSection
                key={section.id}
                isLastSection={index === sections.length - 1}
                {...section}
                domain={(pageContext as any)?.domain}
              />
            ),
        )}
    </Layout>
  );
};

export default TrendsMap;

export const trendsMapQuery = graphql`
  query templatePageQuerytrends($slug: String!, $domain: String!) {
    pageData: datoCmsTemplatePage(slug: { eq: $slug }, domain: { eq: $domain }) {
      ...datoCmsTemplatePage
    }
    allDatoCmsEntitySmugglingPlace {
      nodes {
        ...datoCmsEntitySmugglingPlace
      }
    }
  }
`;

import { FC } from 'react';

import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';

import Icon from 'molecules/Icon/Icon';

interface BasicCardProps extends FlexProps {
  place?: any;
}

const TrendsMapCard: FC<BasicCardProps> = ({ place, ...props }) => (
  <Flex
    paddingAll={24}
    borderRadius="16px"
    border="1px solid"
    borderColor="primary-300"
    backgroundColor="primary-50"
    flexDirection="column"
    gap="8px"
    {...props}
  >
    <Flex gap="16px" alignItems="center" marginBottom={16}>
      <Flex
        width="48px"
        height="48px"
        borderRadius="8px"
        backgroundColor={place.color}
        alignItems="center"
        justifyContent="center"
      >
        <Icon iconName="mark" size={20} color={color.common.white} />
      </Flex>
      <Heading as="h3" textStyle="xxs" textColor="gray-900">
        {place?.categories?.[0]?.name || 'Unknown'}
      </Heading>
    </Flex>
    <Flex textColor="gray-700" fontSize="textMd" gap="4px">
      <Text fontWeight="bold">Date: </Text>
      <Text textTransform="capitalize">{place.date}</Text>
    </Flex>
    <Flex textColor="gray-700" fontSize="textMd" gap="4px">
      <Text fontWeight="bold">Origin: </Text>
      <Text textTransform="capitalize">{place.origin}</Text>
    </Flex>
    <Flex textColor="gray-700" fontSize="textMd" gap="4px">
      <Text fontWeight="bold">Destination: </Text>
      <Text textTransform="capitalize">{place.destination}</Text>
    </Flex>
    <Flex textColor="gray-700" fontSize="textMd" gap="4px">
      <Text fontWeight="bold">Point of Seizure: </Text>
      <Text textTransform="capitalize">{place.pointOfSeizure}</Text>
    </Flex>
    <Flex textColor="gray-700" fontSize="textMd" gap="4px">
      <Text fontWeight="bold">Amount: </Text>
      <Text textTransform="capitalize">{place.amount}</Text>
    </Flex>
    <Flex textColor="gray-700" fontSize="textMd" gap="4px">
      <Text fontWeight="bold">Was NII Used?: </Text>
      <Text textTransform="capitalize">{place.wasNiiUsed}</Text>
    </Flex>
    <Flex textColor="gray-700" fontSize="textMd" gap="4px">
      <Text fontWeight="bold">Manifested Commodity: </Text>
      <Text textTransform="capitalize">{place.manifestedCommodity}</Text>
    </Flex>
  </Flex>
);

export default TrendsMapCard;

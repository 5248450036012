import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import { FC, useCallback, useEffect, useState } from 'react';

import mapData from 'assets/data/map.json';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { DatoCmsEntitySmugglingPlace } from 'graphqlTypes';

declare global {
  interface Window {
    google: any;
  }
}
type HeroProps = BasicProps & {
  activeCat: string;
  activeOrigin: string;
  activeDestination: string;
  activePos: string;
  places: DatoCmsEntitySmugglingPlace[];
};

const containerStyle = {
  width: '100%',
  height: '500px',
};

const TrendsMapHero: FC<HeroProps> = ({ activeCat, activeOrigin, activeDestination, activePos, places }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDTNtuTFnNFoMqV-8YwAicZXvF_jDGMtMg',
    libraries: ['geometry', 'places'],
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const [filtered, setFiltered] = useState<any[]>(places);

  const onLoad = useCallback((map: any) => {
    map.setOptions('baseChange', { baseChange: mapData.map_style_options });
  }, []);

  const onUnmount = useCallback(() => undefined, []);

  useEffect(() => {
    const filteredData = places.filter(
      place =>
        (activeCat === '' || activeCat === place.categories?.[0]?.name) &&
        (activeOrigin === '' || activeOrigin === place.origin) &&
        (activeDestination === '' || activeDestination === place.destination) &&
        (activePos === '' || activePos === place.pointOfSeizure),
    );
    setFiltered(filteredData);
  }, [activeCat, activeOrigin, activeDestination, activePos]);

  return (
    <Flex paddingTop={128} flexDirection="column">
      <Container
        width="100%"
        height="250px"
        overflowX="hidden"
        overflowY="hidden"
        position="absolute"
        bottom={0}
        left={0}
        md={{ height: '300px' }}
        lg={{ height: '400px' }}
        backgroundColor="secondaryGrayBlue-25"
      >
        <Container
          width="150%"
          height="300px"
          position="absolute"
          top="-150px"
          left="50%"
          transform="translateX(-50%)"
          backgroundColor="secondaryGrayBlue-900"
          borderRadius="50%"
          css={{
            boxShadow: '0px 0px 16px #3DB5E6',
          }}
        />
      </Container>
      <Container width="100%" zIndex={10}>
        <Flex
          flexDirection="column"
          gap="24px"
          textAlign="center"
          marginBottom={48}
          md={{ maxWidth: '1008px', marginX: 'auto' }}
          lg={{ marginBottom: 64 }}
        >
          <Heading as="h6" fontSize="textMd" textColor="secondaryTurqoise-400" fontWeight="semiBold">
            Smuggling Trends
          </Heading>
          <Heading as="h1" textStyle="lg" textColor="common-white" fontWeight="semiBold">
            Smuggling Trends Map
          </Heading>
          <Container textColor="gray-50" fontSize="textMd">
            The Smuggling Trends Report tracks recent seizures of smuggled drugs, weapons, currency, humans, cigarettes
            and more from around the globe. The report keeps you informed with videos and articles from customs, police
            and border forces. Share the report with your colleagues to keep your entire organization up to date with
            smuggling techniques from air, sea and land ports.
          </Container>
        </Flex>
        <Container borderRadius="12px" overflowX="hidden" overflowY="hidden" backgroundColor="gray-200" height="500px">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapData.map_options.center}
              zoom={mapData.map_options.zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              mapTypeId="satellite"
              options={{
                styles: mapData.map_style_options,
                fullscreenControl: false,
                mapTypeId: 'satellite',
                mapTypeControl: false,
                streetViewControl: false,
                zoomControlOptions: {
                  position: 1,
                },
              }}
            >
              {filtered.map(place => (
                <>
                  <Marker
                    key={place.id}
                    position={{ lat: parseFloat(place.lat), lng: parseFloat(place.lng) }}
                    icon={place.icon}
                    onClick={() => setActiveMarker(place.id)}
                  />
                  {activeMarker === place.id && (
                    <InfoWindow
                      position={{ lat: parseFloat(place.lat), lng: parseFloat(place.lng) }}
                      onCloseClick={() => setActiveMarker(null)}
                      options={{
                        pixelOffset: {
                          width: 0,
                          height: -22,
                        },
                      }}
                    >
                      <Flex flexDirection="column" gap="4px" textColor="gray-700" maxWidth="250px">
                        <Heading fontSize="textSm" lineHeight="textSm" fontWeight="semiBold">
                          {place.title}
                        </Heading>
                        <Text textStyle="xs">
                          <Text fontWeight="semiBold">Date: </Text> {place.date}
                        </Text>
                        <Text textStyle="xs">
                          <Text fontWeight="semiBold">Destination: </Text> {place.destination}
                        </Text>
                        <Text textStyle="xs">
                          <Text fontWeight="semiBold">Point of Seizure: </Text>{' '}
                          {place.pointOfSeizure}
                        </Text>
                        <Text textStyle="xs">
                          <Text fontWeight="semiBold">Remarks: </Text> {place.remarks}
                        </Text>
                      </Flex>
                    </InfoWindow>
                  )}
                </>
              ))}
            </GoogleMap>
          )}
        </Container>
      </Container>
    </Flex>
  );
};

export default TrendsMapHero;

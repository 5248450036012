import { Dispatch, FC, SetStateAction } from 'react';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';

import Select from 'molecules/Select/Select';

import ComponentButton from 'components/ComponentButton/ComponentButton';

interface ComponentFormProps extends FlexProps {
  category: string[];
  origin: string[];
  destination: string[];
  pos: string[];
  setCategory: Dispatch<SetStateAction<string>>;
  setOrigin: Dispatch<SetStateAction<string>>;
  setDestination: Dispatch<SetStateAction<string>>;
  setPos: Dispatch<SetStateAction<string>>;
}

const FilterForm: FC<ComponentFormProps> = ({
  category,
  origin,
  destination,
  pos,
  setCategory,
  setOrigin,
  setDestination,
  setPos,
  ...props
}) => (
  <Flex
    flexDirection="column"
    maxWidth="700px"
    marginX="auto"
    position="relative"
    height="max-content"
    gap="16px"
    {...props}
  >
    <Text textColor="gray-900" textStyle="lg" fontWeight="semiBold">
      Filter by
    </Text>
    <Flex flexDirection="column" gap="24px" textAlign="left" fontSize="textSm" textColor="gray-700">
      <Flex flexDirection="column" gap="6px" width="100%">
        <Text as="label" fontWeight="semiBold" lineHeight="textSm">
          Select Category
        </Text>
        <Select list={['Select Category', ...category]} onChange={e => setCategory(e)} width="100%" />
      </Flex>
      <Flex flexDirection="column" gap="6px" width="100%">
        <Text as="label" fontWeight="semiBold" lineHeight="textSm">
          Origin
        </Text>
        <Select list={['Select Origin', ...origin]} onChange={e => setOrigin(e)} width="100%" />
      </Flex>
      <Flex flexDirection="column" gap="6px" width="100%">
        <Text as="label" fontWeight="semiBold" lineHeight="textSm">
          Destination
        </Text>
        <Select list={['Select Destination', ...destination]} onChange={e => setDestination(e)} width="100%" />
      </Flex>
      <Flex flexDirection="column" gap="6px" width="100%">
        <Text as="label" fontWeight="semiBold" lineHeight="textSm">
          Point of Seizure
        </Text>
        <Select list={['Select Point of Seizure', ...pos]} onChange={e => setPos(e)} width="100%" />
      </Flex>
      <Flex marginTop={8} width="100%">
        <ComponentButton label="Submit" buttonType="fill" width="100%" color="secondary_turqoise_400" />
      </Flex>
    </Flex>
  </Flex>
);

export default FilterForm;
